import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterTwoRow } from '@cotyorg/ccx-organism-footer';
import { Newsletter } from '@cotyorg/ccx-molecules/Newsletter';
import Search from '@cotyorg/ccx-organism-search/components/Search';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { Social } from '@cotyorg/ccx-molecules/Social';

const StandardLayout = ({
  cookieContent,
  search,
  isServedFromLegacySite,
  children,
  locale,
  presentationLocale,
  isOneTrustEnabled,
  newsletter,
}) => (
  <>
    <HeaderTwoRow
      fixed
      logoSrc="/ccx-files/assets/logo.svg"
      chevronIconSrc="/ccx-files/assets/chevron-down.svg"
      leftChevronIconSrc="/ccx-files/assets/arrow-left-white.svg"
      rightChevronIconSrc="/ccx-files/assets/arrow-right-white.svg"
      closeIconSrc="/ccx-files/assets/cross-white.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      themeName="filled"
      navType="featuredProducts"
      search
      searchIconSrc="/ccx-files/assets/search-white.svg"
      logoWidth={238}
      logoHeight={82}
    />

    <Search
      appElement="#app"
      searchIconSrc="/ccx-files/assets/search-white.svg"
      closeIconSrc="/ccx-files/assets/cross-white.svg"
      clearSearchIconSrc="/ccx-files/assets/clear.svg"
      searchURL={R.prop('url')(search)}
      locale={R.prop('locale')(search)}
      presentationLocale={presentationLocale}
      searchPath="/search/"
      searchFieldName="search"
      searchButtonHidden
      isServedFromLegacySite={isServedFromLegacySite}
    />

    {children}
    <div className="social-bar">
      <div className="grid-container">
        <div className="cell x-small-16 medium-8">
          <Social
            socialIconSrc={{
              facebook: '/ccx-files/assets/facebook.svg',
              twitter: '/ccx-files/assets/twitter.svg',
              instagram: '/ccx-files/assets/instagram.svg',
              youtube: '/ccx-files/assets/youtube.svg',
              vk: '/ccx-files/assets/vkontakte.svg',
              weibo: '/ccx-files/assets/weibo.svg',
              wechat: '/ccx-files/assets/wechat.svg',
              qrcode: '/ccx-files/assets/qrcode.png',
              tiktok: '/ccx-files/assets/tiktok.svg',
            }}
            socialAlignment="left"
            linkTarget="_blank"
            iconSize={locale === 'zh-cn' ? 'large' : 'small'}
          />
        </div>
        {R.prop('isKlaviyoEnabled', newsletter) && (
          <div className="cell x-small-16 medium-8">
            <Newsletter />
          </div>
        )}
      </div>
    </div>

    <FooterTwoRow
      isServedFromLegacySite={isServedFromLegacySite}
      countrySelector={{
        visible: true,
      }}
      minusIconSrc="/ccx-files/assets/minus-super-small.svg"
      plusIconSrc="/ccx-files/assets/plus-super-small.svg"
      navigationLinkIconWidth={10}
    />

    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </>
);

StandardLayout.propTypes = {
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }),
  search: PropTypes.shape({
    url: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    contentLabel: PropTypes.string,
    placeholderText: PropTypes.string,
    clearSearchTermHistoryButtonText: PropTypes.string,
    popularSearches: PropTypes.array,
  }),
  isServedFromLegacySite: PropTypes.bool,
  children: PropTypes.node,
  locale: PropTypes.string.isRequired,
  presentationLocale: PropTypes.string.isRequired,
  isOneTrustEnabled: PropTypes.bool,
  newsletter: PropTypes.objectOf(PropTypes.any),
};

export default connect(
  ({ content, breakpoints, status, locale, presentationLocale }) => ({
    cookieContent: R.prop('cookie')(content),
    search: R.prop('search')(content),
    currentBreakpoint: R.prop('current')(breakpoints),
    isServedFromLegacySite: R.prop('isServedFromLegacySite')(status),
    locale,
    presentationLocale,
    isOneTrustEnabled: R.prop('isOneTrustEnabled')(content),
    newsletter: R.prop('newsletter', content),
  })
)(StandardLayout);
